import ReactPhoneInput from 'react-phone-input-2';
import './CustomReactPhoneInput.css';
import 'react-phone-input-2/lib/style.css';
import { Typography } from '@mui/material';

export default function PhoneNoWithCountryCode({
  setPhoneNumber,
  phoneNumber,
  status,
}) {

  return (
    <div>
      <ReactPhoneInput
        specialLabel={false}
        isValid={(value, country) => {
                    if (value.length < 11 && value.length > 2 && country) {
                      return false;
                    }
                    return true;
                  }}
        country='us'
        variant="outlined"
        disabled={status}
        value={phoneNumber}
        placeholder="Enter Phone Number"
        onChange={(phoneno) => setPhoneNumber(phoneno)}
      />
        <Typography variant="body2" style={{fontSize: 10}}>Select the country code from dropdown</Typography>
    </div>
  );
}
