export const ProductServicesbylocation = `query productServicesbylocation($locationid: ID!) {
    productServicesbylocation(locationid: $locationid) {
      productserviceid
      locationid
      businessid
      name
      type
      imgurl
      lifecycle
      shortdiscription
      longdiscription
      tag
      actualprice
      salesprice
      createdby
      updatedby
    }
  }`;

export const ProductServicesbyid = `query productServicesbyid($productserviceid: ID!) {
    productServicesbyid(productserviceid: $productserviceid) {
      productserviceid
      locationid
      businessid
      name
      type
      imgurl
      lifecycle
      ispublish
      shortdiscription
      longdiscription
      tag
      currency
      actualprice
      salesprice
      thanksmessage
      isshowthanksbutton
      isshowfooterlogo
      thanksbuttontext
      thanksbuttontype
      thanksbuttonlink
      thanksbuttonfileurl
      workflowid
      termenable
      termdesc
      captchaenable
      createdby
      updatedby
    }
  }`;

export const ContactInformation = `mutation createContact($firstname: String!, $locationid: ID!, $contactid: ID, $primaryemail: String, $lastname: String, $phonenumber: String, $lifecyclestage: Int!, $status: Int!) {
  createContact(firstname: $firstname, locationid: $locationid, contactid: $contactid, primaryemail: $primaryemail, lastname: $lastname, phonenumber: $phonenumber, lifecyclestage: $lifecyclestage, status: $status) {
    contactid
    primaryemail
    secondaryemail
    firstname
    lastname
    contactavatar
    phonenumber
    locationid
    createdby
    updatedby
    ownerid
    createdAt
    updatedAt
}}`;

export const CreateProductServices = `
mutation createProductServices(
    $locationid:ID,
    $businessid:ID,
    $name:String,
    $type:String,
    $lifecycle:Action,
    $imgurl:[Upload],
    $shortdiscription: String,
    $longdiscription: String,
    $tag: [String],
    $actualprice: Float,
    $salesprice: Float,
    $createdby: ID
){
    createProductServices(
      locationid: $locationid,
      businessid: $businessid,
      name: $name,
      type: $type,
      lifecycle: $lifecycle,
      imgurl: $imgurl,
      shortdiscription: $shortdiscription,
      longdiscription: $longdiscription,
      tag: $tag,
      actualprice: $actualprice,
      salesprice: $salesprice,
      createdby: $createdby
    ){
      productserviceid 
    }
}`;

export const ProductServicestag = `
query productServicestag($locationid:ID!){
  productServicestag(locationid:$locationid)
}
`;

export const EditProductServices = `
mutation editProductServices(
    $productserviceid:ID,
    $locationid:ID,
    $businessid:ID,
    $name:String,
    $type:String,
    $lifecycle:Action,
    $imgurl:[Upload],
    $shortdiscription: String,
    $longdiscription: String,
    $tag: [String],
    $actualprice: Float,
    $salesprice: Float,
    $updatedby: ID
){
   editProductServices(
      productserviceid:$productserviceid,
      locationid: $locationid,
      businessid: $businessid,
      name: $name,
      type: $type,
      lifecycle: $lifecycle,
      imgurl: $imgurl,
      shortdiscription: $shortdiscription,
      longdiscription: $longdiscription,
      tag: $tag,
      actualprice: $actualprice,
      salesprice: $salesprice,
      updatedby: $updatedby
    )
}`;

export const DeleteProductServices = `
mutation deleteProductServices($productserviceid:ID!){
  deleteProductServices(productserviceid:$productserviceid)
}`;
