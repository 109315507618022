/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import PaypalIcon from '../../../images/paypal.svg';
import Stripe from './Stripe';
import Paypal from './Paypal';
import useStyle from './StylesPaymentMethod';
import config from '../../../config';
import { paymentConnectStatus } from '../../../common/const';
import { getDataRequestPublic } from '../../../common/commonfunctions';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';

export default function Payment({
  amount, setPaymentId, setActiveTabId, appointment, contactData, event, organizationData,
  userId, businessId, locationId, eventId,product,setThankYou,objectId, formId, inquiryform,currencyValue,
}) {
  const classes = useStyle();
  const stripePromise = loadStripe(config.paymentKey);
  const [visibility, setVisibility] = useState(true);
  const [paypalData, setPaypalData] = useState([]);
  const [stripeData, setStripeData] = useState([]);
  const PayPalShow = false;
  const getPaypalData = () => {
    if (locationId) {
      getDataRequestPublic(
        `${config.payment}v1/payment-settings/get-payment-settings/${locationId}/PAYPAL`,
      )
        .then((response) => {
          if (response?.data) {
            setPaypalData(response?.data[0]);
          } else {
            showNotification('Not able to process now, please try after sometime', 'error');
          }
        })
        .catch((err) => {
          showNotification(err?.response?.data?.message, 'error');
        });
    }
  };

  const getStripeData = () => {
    if (locationId) {
      getDataRequestPublic(
        `${config.payment}v1/payment-settings/get-payment-settings/${locationId}/STRIPE`,
      )
        .then((response) => {
          if (response?.data) {
            setStripeData(response?.data[0]);
          } else {
            showNotification('Not able to process now, please try after sometime', 'error');
          }
        })
        .catch((err) => {
          showNotification(err?.response?.data?.message, 'error');
        });
    }
  };

  useEffect(() => {
    getStripeData();
    if (PayPalShow) {
      getPaypalData();
    }
  }, []);

  return (
    <div className={(!inquiryform && !event) ? classes.root : ''}>
      <Box className={classes.menu}>
      {stripeData?.status === paymentConnectStatus?.ACTIVE ? (
        <Paper onClick={() => setVisibility(true)} variant="outlined" className={visibility === true ? classes.subMenuSelect : classes.subMenu} elevation={3}>
          <CreditCardIcon />
          <Typography variant="body1" className={classes.methodName}>Card</Typography>
        </Paper>
        ) : ''}
        {/* {paypalData?.status === paymentConnectStatus?.ACTIVE ? (
        <Paper onClick={() => setVisibility(false)} variant="outlined" className={visibility === true ? classes.subMenu : classes.subMenuSelect} elevation={3}>
          <img className={classes.paypalIcon} src={PaypalIcon} alt="paypal" />
          <Typography variant="body1" className={classes.methodName}>Paypal</Typography>
        </Paper>
        ) : ''} */}
      </Box>
      {visibility === true && stripeData?.status === paymentConnectStatus?.ACTIVE ? (
        <Elements stripe={stripePromise}>
          <Stripe
            userId={userId}
            businessId={businessId}
            locationId={locationId}
            contactData={contactData}
            organizationData={organizationData}
            appointment={appointment}
            amount={amount}
            setThankYou={setThankYou}
            setPaymentId={setPaymentId}
            setActiveTabId={setActiveTabId}
            eventId={eventId}
            objectId={objectId}
            currencyValue={currencyValue}
            event={event}
            product={product}
            formId={formId}
            inquiryform={inquiryform}
          />
        </Elements>
      ) : paypalData?.status === paymentConnectStatus?.ACTIVE ? (
        <Paypal
          userId={userId}
          businessId={businessId}
          locationId={locationId}
          contactData={contactData}
          organizationData={organizationData}
          appointment={appointment}
          amount={amount}
          setThankYou={setThankYou}
          accountId={paypalData?.accountid}
          accountEmail={paypalData?.email}
          product={product}
          setPaymentId={setPaymentId}
          setActiveTabId={setActiveTabId}
          eventId={eventId}
          objectId={objectId}
          event={event}
          formId={formId}
          inquiryform={inquiryform}
        />
      ) : ''}
    </div>
  );
}
